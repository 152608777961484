





























































import Vue from "vue";
import { Product } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import PricingTier from "../components/PricingTier.vue";

@Component({ components: { PricingTier } })
export default class SubscriptionPricing extends Vue {
  @Getter("products/getting") getting!: boolean;
  @Getter("products/retrieved") retrieved!: boolean;
  @Getter("products/products") products!: Product[];
  @Action("products/get") getProducts!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  isYearly: boolean = false;

  get active() {
    // Filter products with metadata type=user only
    const userProducts = this.products.filter(product => {
      return (
        product.metadata &&
        (product.metadata.type === "user" ||
          product.metadata.type === "enterprise")
      );
    });
    // Order by monthly price
    return userProducts.sort((a, b) => {
      return a.unitAmountMonthly - b.unitAmountMonthly;
    });
  }
  created() {
    if (!this.getting && !this.retrieved) this.getProducts();
  }
}
